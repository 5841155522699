<script>
import Vue from "vue";
import { Icon } from "vant";
import { Loading, PullRefresh, ActionSheet, Toast } from "vant";
import dayjs from 'dayjs';
import Act from "../outLogin/index.vue";
import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import Empty from "@/components/Empty.vue";

Vue.use(PullRefresh);
Vue.use(ActionSheet);
// Vue.use(Empty);
Vue.use(Loading);
Vue.use(Icon);
export default {
  components: {
    Act,
    InternalPagesLayout,
    Empty,
  },
  data() {
    return {
      time: "",
      today: new Date().toISOString().slice(0, 10),
      cardData: false, //数据
      dataNull: false,
      selTime: [],
      thisIndex: 0,
      isLoading: false, //刷新
    };
  },
  methods: {
    onRefresh() {
      this.$router.go(0);
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
    },
    //更新用户数据
    async getUser() {
      if (localStorage.auth_token) {
        const res = await this.$api.UserInfo.getUser();
        if (res.errorCode == 1000) {
          this.$store.commit("changeLoginState", res.data);
        }
      }
    },
    //竞猜详情跳转
    toBet(id) {
      this.$router.push({
        path: "/choujiang/bet",
        query: {
          id: id,
        },
      });
    },
    toLuck() {
      this.$router.push({ name: "lotteryIndex" }); //摇一摇跳转
    },
    //获取竞猜数据
    async getSub() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      _this.gettime = yy + "-" + mm + "-" + dd;
      let todayA = _this.gettime.replace(new RegExp("-", "gm"), "/");
      var todayMs = new Date(todayA).getTime(); //得到毫秒数,1天86400000
      // this.selTime.push((todayMs-86400000))
      new Date().getFullYear();
      // this.selTime.push(
      //   new Date(todayMs - 86400000).getFullYear() +
      //     "-" +
      //     (new Date(todayMs - 86400000).getMonth() + 1 < 10
      //       ? "0" + (new Date(todayMs - 86400000).getMonth() + 1)
      //       : new Date(todayMs - 86400000).getMonth() + 1) +
      //     "-" +
      //     (new Date(todayMs - 86400000).getDate() < 10
      //       ? "0" + new Date(todayMs - 86400000).getDate()
      //       : new Date(todayMs - 86400000).getDate())
      // );
      // this.selTime.push(
      //   new Date(todayMs).getFullYear() +
      //     "-" +
      //     (new Date(todayMs).getMonth() + 1 < 10
      //       ? "0" + (new Date(todayMs).getMonth() + 1)
      //       : new Date(todayMs).getMonth() + 1) +
      //     "-" +
      //     (new Date(todayMs).getDate() < 10
      //       ? "0" + new Date(todayMs).getDate()
      //       : new Date(todayMs).getDate())
      // );
      // for (let i = 1; i <= 7; i++) {
      //   var ttime =
      //     new Date(todayMs + 86400000 * i).getFullYear() +
      //     "-" +
      //     (new Date(todayMs + 86400000 * i).getMonth() + 1 < 10
      //       ? "0" + (new Date(todayMs + 86400000 * i).getMonth() + 1)
      //       : new Date(todayMs + 86400000 * i).getMonth() + 1) +
      //     "-" +
      //     (new Date(todayMs + 86400000 * i).getDate() < 10
      //       ? "0" + new Date(todayMs + 86400000 * i).getDate()
      //       : new Date(todayMs + 86400000 * i).getDate());
      //   this.selTime.push(ttime);
      // }
      // console.log(this.selTime);

      const format = 'YYYY-MM-DD';
      
      new Array(8).fill('').map((_, index) => {
        console.log(index)
        this.selTime.push(dayjs().add(index, 'day').format(format))
      })

      // 因为活动是从 2024-07-03 开始的
      // 如果今天是 2024-07-03 之后的一天则需要展示前一天的竞猜数据
      if (dayjs(this.selTime[0]).isAfter(dayjs('2024-07-03'))) {
        this.thisIndex = 1;
        this.selTime.unshift(dayjs().subtract(1, 'day').format(format))
      }

      console.log(this.selTime)

      this.time = _this.gettime;
      const res = await this.$api.Subject.getSubjectList({
        date: _this.gettime,
      });
      if (res.errorCode == 1000) {
        this.cardData = res.data;
      } else {
        this.dataNull = true;
        this.cardData = false;
      }
    },
    //日期跳转
    async xzDate(num, date) {
      this.thisIndex = num;
      const res = await this.$api.Subject.getSubjectList({
        date: date.substring(0, 10),
      });
      if (res.errorCode == 1000) {
        this.cardData = res.data;
      } else {
        this.cardData = false;
        this.dataNull = true;
      }
    },
  },
  created() {
    this.getSub();
    this.getUser();
  },
  deactivated() {
    this.$destroy("Home");
  },
};
</script>

<template>
  <InternalPagesLayout>
    <div class="scroll_y">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        style="min-height: 75vh"
      >
        <div class="scroll">
          <div
            v-for="(item, index) in selTime"
            :key="index"
            :class="index == thisIndex ? 'date' : ''"
            @click="xzDate(index, item)"
          >
            {{ item.substring(5, 7) }}月{{ item.substring(8, 10) }}日
          </div>
        </div>
        <div v-if="cardData.length != 0">
          <!-- 竞猜列表 -->
          <div class="main">
            <!-- 投注列表渲染 -->
            <div class="card" v-for="(item, index) in cardData" :key="index">
              <div class="title">
                <div>
                  <p class="bigFont">
                    {{ item.created_at.substring(5, 10) }}
                  </p>
                  <img
                    v-if="item.created_at.substring(0, 10) == today"
                    class="today"
                    height="20px"
                    src="@/assets/images/today.png"
                    alt=""
                  />
                </div>
                <p v-if="item.status == 1">
                  投注结束时间 : {{ item.ent_time.substring(5) }}
                </p>
                <p v-else>奖池总竞猜币： {{ item.integrate_sum }}竞猜币</p>
              </div>

              <div class="main_section" @click="toBet(item.id)">
                <div>
                  <p class="subject_name">题目:{{ item.name }}</p>
                </div>
                <div
                  v-for="(it, ind) in item.subject_option"
                  :key="it.id"
                  class="main_dis"
                  :style="ind == 0 ? 'margin-top: 10px' : ''"
                >
                  <p
                    class="max_width"
                    :style="it.content.length > 12 ? 'font-size:12px' : ''"
                  >
                    {{ ind + 1 }}、{{ it.content }}
                  </p>
                  <p class="odds">赔率： {{ it.the_odds }}</p>
                </div>
                <button class="bet" v-if="item.status == 1">前往投注</button>
                <button
                  class="bet"
                  disabled
                  style="background: #d0d7e6"
                  v-else-if="item.status == 2"
                >
                  已封盘
                </button>
                <button class="bet" disabled style="background: #ccc" v-else>
                  已撤销
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 无数据时显示 -->
        <div v-else style="margin-top: 80px">
          <!-- 横向title -->
          <Empty message="暂无数据" />
        </div>
      </van-pull-refresh>

    </div>
  </InternalPagesLayout>
</template>

<style lang="less" scoped>
.scroll_y {
  margin-bottom: 100px;
}
.surplus {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgb(235, 235, 235);
  margin: 0;
  align-items: center;
  background: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  .div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ddd;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      margin-right: 9px;
    }
  }
  span {
    color: #e96346;
  }
}
.bigFont {
  font-size: 16px;
}
.scroll {
  width: 90%;
  display: flex;
  // justify-content: space-around;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  // background: red;
  margin-left: 5%;
  height: 40px;
  &::-webkit-scrollbar {
    display: none;
  }
  div {
    margin-right: 68px;
    line-height: 40px;
  }
  .date {
    border-bottom: 1px solid #e96346;
  }
}
.card {
  box-shadow: 0 0 5px 0 #ddd;
  font-size: 14px !important;
  background: white;
  padding: 0 10px;
}
.main {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  margin-top: 10px;
}
.title {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
    }
  }
  .today {
    line-height: 20px;
    color: white;
    border-radius: 7px 0 7px 0;
    background: #79cdf4;
    margin-left: 5px;
    text-align: center;
    transform: scale(0.8);
    font-size: 12px;
  }
}

.main_section {
  width: 100%;
  padding: 10px;
  // border-radius: 10px;
  // border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  .subject_name {
    text-align: left;
    line-height: 20px;
    letter-spacing: 1px;
    // white-space: normal;
  }
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  .main_dis {
    display: flex;
    justify-content: space-between;
    min-height: 34px;
    width: 100%;
    background: #f8f9fc;
    // background: red;
    margin-bottom: 5px;
    align-items: center;
    padding: 0;
    padding: 10px 10px 0px 10px;
    box-sizing: border-box;
    .max_width {
      width: 75%;
      text-align: left;
      word-break: break-all;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .odds {
      color: #999;
      min-width: 85px;
      // text-align: right;
    }
  }
  .bet {
    margin: 0;
    height: 40px;
    background: #e96346;
    border: none;
    width: 90%;
    color: white;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;

    .icon {
      vertical-align: text-top;
      font-size: 14px;
    }
  }
}
.luck_draw {
  position: fixed;
  z-index: 500;
  right: 10px;
  top: 450px;
  text-align: center;
  // line-height: 50px;
}
@media screen and (max-width: 320px) {
  .bet {
    font-size: 14px;
  }
  .bigFont {
    font-size: 14px;
  }
}
</style>
